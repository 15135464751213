import { render, staticRenderFns } from "./LicensesCreatePage.vue?vue&type=template&id=6b374632&scoped=true"
import script from "./LicensesCreatePage.vue?vue&type=script&lang=js"
export * from "./LicensesCreatePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b374632",
  null
  
)

export default component.exports