<template>
  <v-sheet v-bind="propsCompSheet" data-cy="licenses-create">
    <div class="mb-4">
      <v-form :disabled="formDisabled" @submit.prevent>
        <v-text-field
          v-bind="propsFormFields"
          :label="$t('common.name')"
          v-model="editName"
          :error-messages="editNameErrors"
          @input="$v.editName.$touch()"
          @blur="$v.editName.$touch()"
          type="text"
        >
        </v-text-field>
        <v-textarea
          v-bind="propsFormFields"
          :label="$t('common.description')"
          v-model="editDescription"
          :error-messages="editDescriptionErrors"
          @input="$v.editDescription.$touch()"
          @blur="$v.editDescription.$touch()"
          type="text"
        >
        </v-textarea>
      </v-form>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <v-btn
        v-bind="propsButtonAction"
        :loading="status.loading"
        :disabled="!formReadyForSubmit"
        @click="clickCreate"
        data-cy="button-create"
        >{{ $t("common.create") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "licensesCreate",
  mixins: [ComponentStatus],
  components: {},
  data: () => ({
    // New proxy form data
    editName: "",
    editDescription: "",
  }),
  validations: {
    editName: {
      required,
      maxLength: maxLength(100),
    },
    editDescription: {
      maxLength: maxLength(1000),
    },
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      if (!this.$v.editDescription.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
  },
  methods: {
    clickCreate() {
      this.setStatusCreating();
      let payload = {
        name: this.editName,
        description: this.editDescription,
      };
      this.$store
        .dispatch("licenses/create", payload)
        .then((result) => {
          // Removing all errors from server.
          this.$v.$touch();
          // Setting component status to success.
          this.setStatusCreateSuccess();
          this.$store.commit("status/showSuccess");
          if (result) {
            this.$router.push({
              name: "licenses-show",
              params: {
                id: result,
              },
            });
          } else {
            this.$router.push({
              name: "licenses-index",
            });
          }
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          // Setting component status to failure.
          this.setStatusCreateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
